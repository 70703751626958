var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { ImagePreview } from 'Components.Camera';
import { Button } from 'Components.Form';
import { FormattedMessage } from 'Intl';
import styled from 'styled-components';
var CameraWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  #outer-circle {\n    left: -37px;\n    height: 75px;\n    width: 75px;\n    background-color: hsl(7deg 100% 50% / 40%)!important;\n    z-index: 1;\n  }\n  #inner-circle {\n    left: 50%;\n    top: 38px;\n    height: 44px;\n    width: 44px;\n    background: #ff0000!important;\n    margin: -22px 0 0 -22px;\n    z-index: 2;\n  }\n"], ["\n  #outer-circle {\n    left: -37px;\n    height: 75px;\n    width: 75px;\n    background-color: hsl(7deg 100% 50% / 40%)!important;\n    z-index: 1;\n  }\n  #inner-circle {\n    left: 50%;\n    top: 38px;\n    height: 44px;\n    width: 44px;\n    background: #ff0000!important;\n    margin: -22px 0 0 -22px;\n    z-index: 2;\n  }\n"])));
var CameraTools = function (props) {
    var _a = useState(''), dataUri = _a[0], setDataUri = _a[1];
    var handleTakePhotoAnimationDone = function (nextDataUri) {
        setDataUri(nextDataUri);
    };
    var handleTakePhoto = function (nextDataUri) {
        props.onConfirm(nextDataUri);
    };
    var handleRetake = function () {
        setDataUri('');
    };
    return (React.createElement("div", null, (dataUri)
        ? (React.createElement(React.Fragment, null,
            React.createElement(ImagePreview, { dataUri: dataUri }),
            React.createElement(Button, { id: "".concat(props.baseId, "_retake_button"), primary: true, fullWidth: true, onClick: handleRetake },
                React.createElement(FormattedMessage, { id: 'cameraTools.retakePhoto' }))))
        : (React.createElement(CameraWrapper, null,
            React.createElement(Camera, { onTakePhoto: handleTakePhoto, onTakePhotoAnimationDone: handleTakePhotoAnimationDone, idealFacingMode: FACING_MODES.ENVIRONMENT, idealResolution: { width: 640, height: 480 }, imageType: IMAGE_TYPES.JPG, imageCompression: 0.97, isMaxResolution: true, isImageMirror: false, isSilentMode: false, isDisplayStartCameraError: true, isFullscreen: false, sizeFactor: 1 })))));
};
CameraTools.defaultProps = {
    baseId: '',
};
export default CameraTools;
var templateObject_1;
